import React from "react";
import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import SelectField from "../../../../../../common/components/Form/SelectField";
import InputField from "../../../../../../common/components/Form/InputField";
import SearchList from "../../../../../../common/components/UI/SearchList";
import MemberSearch from "./MemberSearch";
import MemberList from "./MemberList";
import useFormSubmission from "../../hooks/useFormSubmission";
import { getMembers, getSelectedMembers } from "../../api";
import { getFormValues, useTravelPackWizardStore } from "../../store";
import { useGlobalStore } from "../../../../../../store/GlobalStore";
import { MemberType } from "../../constant";
import { useAppStore } from "../../../../../../store/AppStore/AppStore";

// const members = [
//   {
//     id: 1,
//     name: "lanka",
//     src: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?semt=ais_hybrid",
//     designation: "Business Analyst",
//   },
//   {
//     id: 2,
//     name: "Nuwan",
//     src: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?semt=ais_hybrid",
//     designation: "Project Manager",
//   },
// ];
const initialValues = {
  crew: [],
  office: [],
  talents: [],
};
const StepKey = "step_02";

const TeamContact = ({ mode, onSubmitted }) => {
  const hideLoader = useAppStore((state) => state.hideLoader);
  const showLoader = useAppStore((state) => state.showLoader);

  const [crewMembers, setCrewMember] = React.useState([]);
  const [officeMembers, setOfficeMember] = React.useState([]);
  const [talentMembers, setTalentMember] = React.useState([]);

  const currentTravelPack = useTravelPackWizardStore(
    (state) => state.currentTravelPack
  );

  const projectId = Number(
    useGlobalStore((state) => state.selectedProjectId) || 0
  );

  const [selectedCrew, setSelectedCrew] = React.useState([]);
  const [selectedOffice, setSelectedOffice] = React.useState([]);
  const [selectedTalents, setSelectedTalents] = React.useState([]);

  React.useEffect(() => {
    showLoader();
    getMembers(
      currentTravelPack?.shootLegId || 0,
      projectId,
      MemberType.Crew
    ).then((members) => {
      setCrewMember(members);
    });

    getMembers(
      currentTravelPack?.shootLegId || 0,
      projectId,
      MemberType.ProductionOffice
    ).then((members) => {
      setOfficeMember(members);
    });

    getMembers(
      currentTravelPack?.shootLegId || 0,
      projectId,
      MemberType.Talent
    ).then((members) => {
      setTalentMember(members);
    });
    hideLoader();
  }, [currentTravelPack?.shootLegId, hideLoader, projectId, showLoader]);

  // Form submit hook
  const { formik } = useFormSubmission({
    key: StepKey,
    initialValues,
    onSubmitted,
  });

  const { values, handleChange, setFieldValue } = formik;

  React.useEffect(() => {
    showLoader();
    getSelectedMembers(currentTravelPack?.shootLegId || 0).then((members) => {
      setSelectedCrew(members?.crew || []);
      setSelectedOffice(members?.office || []);
      setSelectedTalents(members?.talents || []);
      // Set form values
      setFieldValue("crew", members?.crew || []);
      setFieldValue("office", members?.office || []);
      setFieldValue("talents", members?.talents || []);
      hideLoader();
    });
  }, [currentTravelPack?.shootLegId, hideLoader, setFieldValue, showLoader]);

  const getCrewList = React.useMemo(() => {
    return selectedCrew?.map((d) => {
      return crewMembers.find((m) => m?.id === d?.id);
    });
  }, [crewMembers, selectedCrew]);

  const getOfficeList = React.useMemo(() => {
    return selectedOffice?.map((d) => {
      return officeMembers.find((m) => m?.id === d?.id);
    });
  }, [officeMembers, selectedOffice]);

  const getTalentList = React.useMemo(() => {
    return selectedTalents?.map((d) => {
      return talentMembers.find((m) => m?.id === d?.id);
    });
  }, [talentMembers, selectedTalents]);

  return (
    <form>
      <div className="grid g2col">
        <div className="g-col full-width">
          <FieldGroup label="Crew">
            <MemberSearch
              name="Crew"
              members={crewMembers}
              value={selectedCrew}
              onChange={(val) => {
                setSelectedCrew(val);
                setFieldValue("crew", val);
              }}
            />

            <MemberList
              droppableId="crew"
              title="Selected Crew Members"
              data={getCrewList}
              onChange={(values = []) => {
                // const ids = values.map((d) => d?.id);
                setSelectedCrew(values);
              }}
            />
          </FieldGroup>
        </div>

        <div className="g-col full-width">
          <FieldGroup label="Production Office">
            <MemberSearch
              members={officeMembers}
              value={selectedOffice}
              onChange={(val) => {
                setSelectedOffice(val);
                setFieldValue("office", val);
              }}
            />

            <MemberList
              droppableId="offices"
              title="Selected Production Offices"
              data={getOfficeList}
              onChange={(values = []) => {
                // const ids = values.map((d) => d?.id);
                setSelectedOffice(values);
              }}
            />
          </FieldGroup>
        </div>

        <div className="g-col full-width">
          <FieldGroup label="Talent">
            <MemberSearch
              members={talentMembers}
              value={selectedTalents}
              onChange={(val) => {
                setSelectedTalents(val);
                setFieldValue("talents", val);
              }}
            />

            <MemberList
              droppableId="talents"
              title="Selected Talents"
              data={getTalentList}
              onChange={(values = []) => {
                // const ids = values.map((d) => d?.id);
                setSelectedTalents(values);
              }}
            />
          </FieldGroup>
        </div>
      </div>
    </form>
  );
};

export default TeamContact;
