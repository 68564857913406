import React from "react";
import { Formik } from "formik";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getInputs } from "./utility";
import Tabs from "../Tabs";
import InputControl from "./InputControl";
import InputsList from "./InputsList";

import * as Styled from "../styled";
import { TAG_MODAL_MODE, useResearchBoardStore } from "../../../../../store/ResearchBoardStore";
import UIButton from "../../../../../../../common/components/Buttons/UIButton";
import useIcon from "../../../../../../../common/components/IconSelector/hooks/useIcon";
import { FieldTypes } from "../../../../../../../constant/FieldTypes";
import PreviewIcon from "../../../../../../../common/components/icons/Line/View/01";
import UnlinkIcon from "../../../../../../../common/components/icons/Fill/Unlink/01";
import AddIcon from "../../../../../../../common/components/icons/Line/Add/05";
import AddRelationshipPopup from "../../Components/TagRelationshipPopup";
import { getTagRelationshipByParentId } from "../../../../../api/common/tags";
import { deleteTagRelationship } from "../../../../../api/dynamic-form";
import useTagTypes from "../../../../../hooks/useTagTypes";
export const RESEARCH_FILED_DISPLAY_TYPE = {
  NA: 0,
  TITLE: 1,
  SUBTITLE: 2,
  DISCRIPTION: 3,
  VISUAL: 4,
};
export const RESEARCH_TAG_VISUAL_TYPE = {
  NA: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  LOCATION: 4,
  SLIDER: 5,
  EMPTY: 100,
};
function getValue(value) {
  if (value === Object(value)) {
    return value.label;
  } else {
    return value;
  }
}

const getFromData = (inputs, formData) => {
  const __values = [];

  for (let i = 0; i < inputs.length; i++) {
    for (let j = i; j < formData.length; j++) {
      if (String(inputs[i].id()) === formData[j].tagFieldId) {
        const __field = inputs[i];

        __values.push({
          tagFieldId: __field.id(),
          fieldTypeId: __field.fieldType().id(),
          tagSubTypeId: __field.tagSubTypeId(),
          displayTypeId: __field.displayTypeId(),
          visualTypeId: __field.visualTypeId(),
          layoutTypeId: __field.layoutTypeId(),
          displayName: __field.label(),
          tagSubTypeName: __field.tagSubTypeName(),
          icon: __field.icon() || "",
          value: formData[j].value,
        });
      }
    }
  }

  return __values;
};

const getRowData = (values) => {
  const rowFormData = [];

  for (let key in values) {
    const tagFieldId = key.split("__")[1];
    rowFormData.push({
      tagFieldId,
      value: values[key],
    });
  }

  return rowFormData;
};

const TagForm = ({
  data,
  tagData,
  onSubmit,
  mode,
  onCancel,
  sectionVisibilityStatus,
  isLocked,
  currentFocusTab,
  setMainField,
  showMainField,
  setShouldClose,
}) => {

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [relationships, setRelationships] = React.useState([]);
  const [realtionshipIdToBeDelete, setRealtionshipIdToBeDelete] = React.useState();
  const onShowPreviewModal = useResearchBoardStore((state) => state.onShowPreviewModal);
  const onCloseTagModal = useResearchBoardStore((state) => state.onHideTagModal);
  const { tagTypeItems } = useTagTypes("tags-in-modal", {
    enabled: !!true,
  });

  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleUnlink = async (relationshipId) => {
    if (!relationshipId) 
      {
        console.warn("No relationshipId Id");
        return;
      }

    try {
      const response = await deleteTagRelationship(relationshipId);
      console.log("Relationship deleted successfully:", response);
      setRealtionshipIdToBeDelete(relationshipId);
      
      toast.success("Relationship removed!", {
        autoClose: 2500,
      });

    } catch (error) {
      console.error("Error removing relationship:", error);
    }
  };

  React.useEffect(() => {
    const fetchTags = async () => {
        try {
          const relationshipsData = await getTagRelationshipByParentId(tagData.tagId);
          console.log("TagForm--- relationshipsData: ", relationshipsData);
          console.log("TagForm--- TagData: ", tagData);
          setRelationships(relationshipsData);

         
        } catch (err) {
          console.error("Error fetching tags:", err);
        } 
    };

    fetchTags();
  }, [tagData, isPopupOpen, realtionshipIdToBeDelete]);

  // If form fields are not available
  // then return
  const fieldList = data?.fields || null;
  const findIcon = useIcon();

  const customTabItems = [
    {
      id: "relationship",
      name: "Relationship",
      visibility: true,
      icon: "fill-users", 
    },
    ...sectionVisibilityStatus,
  ];

  const tabItems = React.useMemo(() => {
    let items = sectionVisibilityStatus;
    if(mode === TAG_MODAL_MODE.Edit)
    {
      items =customTabItems
    }
    // const items = data?.tabItems;

    if (!items)
      return {
        data: [],
        canShow: false,
        firstItem: null,
      };

    // const _data = items.filter((group) => group.fieldCount > 0);

    return {
      data: items,
      canShow: items.some((d) => d.visibility === true),
      firstItem: items.find((d) => d.visibility === true) || null,
    };
  }, [customTabItems, sectionVisibilityStatus]);


  const formInputs = React.useMemo(() => {
    let inputsObj = getInputs(fieldList);
    return inputsObj;
  }, [fieldList]);

  const onSubmitHandler = (values, actions) => {
    let val = {};
    let unsortedValues = [];

    for (const key in values) {
      if (key.includes("customFileUploadManager")) {
        const getUnsortedValues =
          values[key]?.value?.filter((f) => !f.isSorted) || [];

        if (getUnsortedValues.length > 0) {
          unsortedValues.push({
            ...values[key],
            value: getUnsortedValues,
          });
        }
      } else if (key.includes("customGroupField")) {
      } else {
        val[key] = values[key];
      }
    }

    const rowFormData = getRowData(val);
    const formData = getFromData(formInputs.inputs, rowFormData);

    formData.forEach((item) => {
      if (item.fieldTypeId === FieldTypes.TelephoneList && Array.isArray(item.value)) {
        // filtering oyt null/empty telephoneNo values
        item.value = item.value.filter((entry) => entry.telephoneNo);

        if (item.value.length === 0) {
          item.value = [];
        }
      }
      else if (item.fieldTypeId === FieldTypes.TelephoneList && item.value === null){item.value = []}
    });

    onSubmit && onSubmit(formData, actions, unsortedValues);
  };

  const hideTopBorder = React.useMemo(() => {
    if (mode !== TAG_MODAL_MODE.Edit) return false;

    const requiredFieldCount = formInputs?.inputs?.filter(
      (field) =>
        field.isRequired() && field.fieldType().id() !== FieldTypes.GroupFields
    ).length;

    return requiredFieldCount === 1 && !showMainField ? true : false;
  }, [formInputs?.inputs, mode, showMainField]);

  if (fieldList && Object.keys(fieldList).length === 0) {
    return null;
  }

  if (!formInputs?.initialValues) return null;

  return (
    <Formik
      initialValues={formInputs.initialValues}
      validationSchema={formInputs.validationSchema}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Styled.FormContainer>
              {/* Controls */}
              <Styled.FormControls>
                <Styled.BasicControllers
                  className="basic"
                  $mode={mode}
                  $showField={showMainField}
                >
                  <InputsList
                    inputs={formInputs.inputs}
                    showRequiredItems
                    render={(field, i) => (
                      <InputControl
                        setMainField={setMainField}
                        key={field.id()}
                        errors={errors}
                        field={field}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    )}
                  />
                </Styled.BasicControllers>

                {/* Render Tabs  */}

                <Styled.ControllerAccordion
                  hidden={!tabItems.canShow}
                  $hideTopBorder={hideTopBorder}
                >
                  <Tabs
                    activeTab={String(
                      currentFocusTab || tabItems.firstItem?.id || ""
                    ).toLowerCase()}
                  >
                    {tabItems.data.map((tab, i) => {
                      const Icon = findIcon(tab?.icon || "fill-users");

                      return (
                        <Tabs.Panel
                          key={i}
                          tabId={String(tab.id).toLowerCase()}
                          title={tab.name}
                          icon={<Icon />}
                          visibility={tab.visibility}
                        >
                          {tab.id === "relationship" ? (
                            <>
                              {console.log("Rendering relationships: ", relationships)}

                              <Styled.RelationshipContainer>
                                {/* Map and render relationships if available */}
                                {relationships && relationships.length > 0 ? (
                                  relationships.map((relationship) => {
                                    const TagType = tagTypeItems.find(
                                      (type) => type.__typeId === relationship.tagTypeId
                                    );
                                    const TagTypeIcon = findIcon(TagType?.__icon || "research-access-1");

                                    console.log("Rendering individual relationship: ", relationship);
                                    //console.log("Rendering TagType: ", TagType);

                                    const rawFormData = relationship?.tag?.formData;
                                    let formData;
                                    const variant = TagType?.__typeName?.toUpperCase() || "";

                                    try {
                                        if (Array.isArray(rawFormData)) {
                                            formData = rawFormData;
                                        } else if (typeof rawFormData === "string") {
                                            // parse if JSON string
                                            formData = JSON.parse(rawFormData);
                                        } else {
                                            // Default to empty array if neither
                                            console.warn("formData is not an array or valid JSON:", rawFormData);
                                            formData = [];
                                        }
                                    } catch (error) {
                                        console.error("Failed to parse formData:", error);
                                        formData = [];
                                    }
                                    
                                    let subTagsCategorizedTagFileds = [];
                                    let data = formData;

                                    if (!Array.isArray(data)) return null;

                                    data?.forEach((data_element) => {
                                      let fileds = data.filter(
                                        (d) => d.tagSubTypeId === data_element.tagSubTypeId
                                      );
                                    
                                      subTagsCategorizedTagFileds.push({
                                        tagSubTypeId: data_element.tagSubTypeId,
                                        tagSubTypeName: data_element.tagSubTypeName,
                                        tagFileds: fileds,
                                        icon: data_element?.icon || "",
                                      });
                                    });
                                    subTagsCategorizedTagFileds = subTagsCategorizedTagFileds?.filter(
                                      (ele, ind) =>
                                        ind === subTagsCategorizedTagFileds.findIndex((elem) => 
                                          elem.tagSubTypeId === ele.tagSubTypeId ));
                                  
                                    let title = getValue(formData?.filter(
                                        (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.TITLE
                                      )[0]?.value);
                                    let subTitle = getValue(formData?.filter(
                                        (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.SUBTITLE
                                      )[0]?.value);
                                    let visual = formData?.filter(
                                      (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
                                      )[0]?.value;
                                    let visualType = formData?.filter(
                                      (f) => f.displayTypeId === RESEARCH_FILED_DISPLAY_TYPE.VISUAL
                                      )[0]?.visualTypeId;
                                    let visualTypeImages = formData
                                        ?.filter((f) => f?.visualTypeId === 1)
                                        ?.map((d) => d?.value)
                                        ?.filter((d) => d?.length > 0)
                                        ?.map((s) => s?.map((f) => f?.filePath)) || [];

                                        let previewPayload = {
                                          variant,
                                          visual,
                                          visualType,
                                          visualTypeImages,
                                          title,
                                          subTitle,
                                          showContent: visual === undefined ? false : true,
                                          tagData: relationship?.tag,
                                          formData: formData,
                                          subTagsCategorizedTagFileds,
                                        };

                                    return (
                                      <Styled.RelationshipBar key={relationship.relationshipId}>
                                        <Styled.InfoSection>
                                          <span className="icon">
                                            <TagTypeIcon />
                                            <span className="tag-type">
                                              {TagType.__typeName || "Unknown"}
                                            </span>
                                          </span>

                                          <Styled.Divider />

                                          <span className="title">
                                            {relationship.tag?.mainFieldValue || "Unknown"}
                                          </span>
                                        </Styled.InfoSection>
                                        <Styled.ActionSection>
                                          <button className="button view" onClick={() => {
                                            onCloseTagModal();
                                            onShowPreviewModal(previewPayload);
                                            }}>
                                            <PreviewIcon />
                                          </button>
                                          <button className="button remove" onClick={() => 
                                            handleUnlink(relationship.relationshipId)
                                            }>
                                            <UnlinkIcon />
                                          </button>
                                        </Styled.ActionSection>
                                      </Styled.RelationshipBar>
                                    );
                                  })
                                ) : (
                                  <>
                                    {console.log("No relationships found for this tag.")}
                                  </>
                                )}

                                {/* Add New Relationship Popup Btn */}
                                <Styled.AddNewButton type="button" onClick={openPopup}>
                                  <span className="icon">
                                    <AddIcon />
                                  </span>{" "}
                                  Add New
                                </Styled.AddNewButton>

                                {/* Render the popup if Isopen - true */}
                                {isPopupOpen && (
                                  <AddRelationshipPopup
                                    selectedTagData={tagData}
                                    onClose={closePopup}
                                  />
                                )}
                              </Styled.RelationshipContainer>
                            </>
                          ) : (
                            // Render other tabs panel itms
                            <InputsList
                              inputs={formInputs.inputs}
                              tabId={tab.id}
                              render={(field, i) => (
                                <InputControl
                                  tabId={tab.id}
                                  key={i}
                                  errors={errors}
                                  field={field}
                                  handleChange={handleChange}
                                  touched={touched}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            />
                          )}
                        </Tabs.Panel>


                      );
                    })}
                  </Tabs>
                </Styled.ControllerAccordion>
              </Styled.FormControls>

              {/* 
                Action
               */}
              <Styled.FormAction>
                {mode === TAG_MODAL_MODE.Edit ? (
                  tabItems.canShow || !hideTopBorder ? (
                    <div className="line"></div>
                  ) : null
                ) : (
                  <div className="line"></div>
                )}

                <div className="inner">
                  <UIButton
                    type="submit"
                    variant={UIButton.Variant.Primary}
                    size={UIButton.Sizes.Small}
                    className="btn__submit"
                    disabled={isLocked}
                  >
                    {mode === TAG_MODAL_MODE.Edit ? "Update" : "Create"}
                  </UIButton>

                  <UIButton
                    type="button"
                    variant={UIButton.Variant.Primary}
                    shade="200"
                    size={UIButton.Sizes.Small}
                    className="btn__submit__close"
                    disabled={isLocked}
                    onClick={() => {
                      handleSubmit();
                      setShouldClose(true);
                    }}
                  >
                    {mode === TAG_MODAL_MODE.Edit ? "Update" : "Create"} & Close
                  </UIButton>
                </div>
              </Styled.FormAction>
            </Styled.FormContainer>
          </form>
        );
      }}
    </Formik>
  );
};

export default TagForm;
