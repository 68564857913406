import styled, { css } from "styled-components";
import FieldGroup from "../../../../../../common/components/Form/FieldGroup";
import { fontStyle } from "../../../../../../styled/utility";
import PopoverDropdown, {
  PopoverDropdownMenuItem,
} from "../../../../../../common/components/UI/PopoverDropdown";
import { TAG_MODAL_MODE } from "../../../../store/ResearchBoardStore";

export const modalConfigCss = css`
  --modal-max-width: fit-content; // temp solution
  /* --modal-max-width: 30.625rem; */
  --modal-width: 100%;
`;

export const ModalCss = css`
  min-width: 640px;
  max-width: 640px;

  .close__btn {
    top: var(--layout-spacing-24);
    right: var(--layout-spacing-32);
  }
`;

export const ModalHeader = styled.div`
  padding-top: var(--layout-spacing-28);
  padding-inline: var(--layout-spacing-32);

  .inner {
    border-bottom: 1px solid var(--color-neutral-50);

    .top {
      display: flex;
      align-items: center;
      margin-bottom: var(--layout-spacing-8);

      .top__left {
        margin-right: var(--layout-spacing-16);
        display: flex;
        align-items: center;

        .tag__type__icon {
          --icon-button-size: 40px;
          --icon-button-icon-size: 40px;
          --icon-button-icon-color: var(--font-tint-04);
        }
      }

      .top__right {
        display: flex;
        align-items: center;
        gap: var(--layout-spacing-16);
        max-width: 40%;
        /* flex-grow: 1; */

        .start {
          display: flex;
          flex-direction: column;
          min-width: 0;

          .modal__state {
            display: flex;
            align-items: center;
            ${fontStyle("small-regular")};
            color: var(--font-default-color, #061216);
            gap: var(--layout-spacing-4);

            .state__label {
            }

            .state__selector {
            }
          }

          .modal__default__value {
            display: flex;
            align-items: center;
            gap: var(--layout-spacing-8);
            height: var(--layout-size-28);

            .value {
              ${fontStyle("h6-bold")};
              font-weight: 700;
              color: var(--font-color-shade-accent, #3c7f84);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &.placeholder--text {
                color: var(--font-tint-01);
              }
            }

            .edit__btn {
              --icon-button-icon-color: var(--font-tint-02);

              .button__icon {
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }

        .end {
          display: flex;
          align-self: flex-start;

          .add__btn {
            /* margin-left: var(--layout-spacing-16); */
          }
        }
      }
    }

    .content__editor {
      display: flex;
      align-items: center;
      margin-bottom: var(--layout-spacing-4);

      span {
        display: flex;
        align-items: center;
        line-height: 1;
        ${fontStyle("x-small-regular")};
      }

      &--title {
        margin-right: var(--layout-spacing-8);
        color: var(--font-tint-04);
      }

      &--details {
        color: var(--color-primary-100, #6c8b94);
        display: inline-flex;
        align-items: center;

        /* .name {
          display: inline-flex;
        }

        .time {
          display: inline-flex;
          &::before {
            content: "";
            display: inline-flex;
            margin: 0 var(--layout-spacing-8);
            width: 1px;
            height: var(--layout-size-12);
            background-color: var(--color-primary-100);
          }
        } */
      }
    }

    .bottom {
      padding-bottom: var(--layout-spacing-4);
      /* padding-top: var(--layout-spacing-4); */
    }
  }

  .add__option__list {
    max-width: 220px;
    min-width: 220px;

    ul {
      margin: 0 var(--layout-spacing-16);
      display: flex;
      flex-direction: column;
      gap: var(--layout-spacing-4);

      li.section {
        cursor: pointer;
        padding: var(--layout-spacing-8);

        .section__title {
          ${fontStyle("label-medium")};
          color: var(--color-primary-200);
        }

        .section__description {
          ${fontStyle("x-small-regular")};
          color: var(--font-tint-04);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          .section__title {
            color: var(--color-primary-300);
          }
          .section__description {
            color: var(--font-default-color);
          }
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  /* padding-inline: var(--layout-spacing-32);
  padding-top: var(--layout-spacing-16);
  padding-bottom: var(--layout-spacing-28); */
  /* min-height: 300px; */
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  .form__element {
    &:not(:last-child) {
      margin-bottom: var(--layout-spacing-12);
    }
  }
`;

export const FormControls = styled.div`
  min-height: 0;
  flex-grow: 1;
  padding-top: var(--layout-spacing-16);
  padding-inline: var(--layout-spacing-32);
`;

export const FormAction = styled.div`
  margin-top: var(--layout-spacing-16);
  padding-bottom: var(--layout-spacing-28);
  padding-inline: var(--layout-spacing-32);
  position: sticky;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: var(--layout-border-radius-16);
  border-bottom-right-radius: var(--layout-border-radius-16);
  background-color: var(--layout-background-white);

  .inner {
    display: flex;
    align-items: center;
    gap: var(--layout-spacing-8);
  }

  .line {
    margin-bottom: var(--layout-spacing-20);
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--layout-background-primary);
  }

  button {
    flex: 1;
  }

  .btn__submit {
    flex: 0 0 24%;
  }
  .btn__submit__close {
    flex: 1;
  }
`;

export const SelectedTextWrapper = styled.div`
  margin-top: var(--layout-spacing-12);
  padding-inline: var(--layout-spacing-32);

  .text__content {
    background-color: #e8eff1;
    padding: var(--layout-spacing-24);
    border-radius: var(--layout-border-radius-4);
    color: #347084;
    margin-bottom: var(--layout-spacing-16);
    margin-top: var(--layout-spacing-4);
    position: relative;
    ${fontStyle("small-regular")};

    &::before {
      ${fontStyle("x-small-regular")};
      content: "Selected Text";
      color: #aaaeb7;
      position: absolute;
      top: -16px;
      right: 0;
    }
  }
`;

export const BasicControllers = styled.div`
  .main-field-wrapper {
    ${({ $mode, $showField }) => {
      if ($mode !== TAG_MODAL_MODE.Edit) {
        return "display: grid;";
      }

      if ($mode === TAG_MODAL_MODE.Edit) {
        if ($showField) {
          return "display: grid;";
        } else {
          return "display: none;";
        }
      }
    }}
  }
`;

export const ControllerAccordion = styled.div`
  ${({ $hideTopBorder }) => {
    if (!$hideTopBorder) {
      return `
        margin-top: var(--layout-spacing-24);
        border-top: 1px solid var(--layout-background-primary);
      `;
    }
  }}
`;

export const UIFieldGroup = styled(FieldGroup)`
  --field-bottom-space: 12px;
`;

export const WarringMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--layout-spacing-8);
  gap: var(--layout-spacing-8);
  background-color: var(--color-danger-50);
  border-radius: var(--layout-border-radius-4);
  color: var(--color-danger-300);

  .icon {
    --icon-size: var(--layout-size-16);
    --icon-font-size: var(--layout-size-16);
  }

  p {
    ${fontStyle("x-small-medium")};

    margin: 0;
    padding: 0;
  }
`;

export const TagTypeSelector = styled(PopoverDropdown)`
  .button__wrapper {
    width: auto;
    border-radius: var(--layout-border-radius-4);
    padding: var(--layout-spacing-2) var(--layout-spacing-4);

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .dropdown__menu {
    max-height: 330px;
    overflow-y: auto;
  }
`;

export const TagTypeHeader = styled.div`
  max-width: 110px;
  display: flex;
  align-items: center;
  gap: var(--layout-spacing-8);
  justify-content: space-between;

  .text {
    color: var(--font-default-color, #061216);
  }

  .icon {
    --icon-button-icon-color: var(--font-tint-01);
  }
`;

export const TagTypeSelectorItem = styled(PopoverDropdownMenuItem)`
  --bg-color: ${({ $isSelected }) =>
    $isSelected ? "var(--layout-background-n30)" : "transparent"};

  background-color: var(--bg-color);
`;

export const RelationshipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--layout-spacing-16);
`;

export const RelationshipBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  border: 1px dashed var(--layout-background-primary);
  border-radius: var(--layout-border-radius-4);
  background-color: var(--layout-background-white);
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between child elements */
  flex-wrap: nowrap; /* Prevents items from wrapping */

  .icon {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    font-size: 16px;

    .tag-type {
      ${fontStyle("small-regular")};
      color: #787E95;
      margin-left: 6px;
    }
  }
  
  .title {
      ${fontStyle("small-medium")};
      color: #787E95;
      font-weight: 500;
  }
`;


export const Divider = styled.div`
  height: 17px;
  width: 1.3px; 
  background-color: #787E95; 
  margin: 0 15px;

`;

export const ActionSection = styled.div`
  display: flex;
  gap: var(--layout-spacing-8);

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.view {
      border: 1px solid var(--layout-background-primary);
      color: var(--color-primary-100);
    }

    &.remove {
      color: var(--color-primary-100);
    }
  }
`;

export const AddNewButton = styled.button`
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--layout-background-primary);
  border-radius: var(--layout-border-radius-4);
  background-color: #EEF8F8;
  color: #3C7F84;
  cursor: pointer;

  &:hover {
    background-color: var(--layout-background-primary);
  }

  .icon {
    margin-right: var(--layout-spacing-8);
  }
`;

